const Logo = ({ size = 44 }) => {
  return (
    <>
      <svg
        width={size}
        height={size}
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="logo"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.0003 3.55803C34.0312 7.10184 36.8982 9.96876 40.442 9.99975C42.6922 13.4509 44 17.5727 44 22C44 26.4273 42.6922 30.5491 40.442 34.0003C36.8982 34.0312 34.0312 36.8982 34.0003 40.442C30.5491 42.6922 26.4273 44 22 44C17.5727 44 13.4509 42.6922 9.99974 40.442C9.96875 36.8982 7.10184 34.0312 3.55803 34.0003C1.30775 30.5491 0 26.4273 0 22C0 17.5727 1.30775 13.4509 3.55803 9.99975C7.10184 9.96876 9.96876 7.10184 9.99975 3.55803C13.4509 1.30775 17.5727 0 22 0C26.4273 0 30.5491 1.30775 34.0003 3.55803Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.0003 3.55803C30.5491 1.30775 26.4273 0 22 0C17.5727 0 13.4509 1.30775 9.99975 3.55803C9.96876 7.10184 7.10184 9.96876 3.55803 9.99975C1.30775 13.4509 0 17.5727 0 22C0 26.4273 1.30775 30.5491 3.55803 34.0003C7.10184 34.0312 9.96875 36.8982 9.99974 40.442C13.4509 42.6922 17.5727 44 22 44C26.4273 44 30.5491 42.6922 34.0003 40.442C34.0312 36.8982 36.8982 34.0312 40.442 34.0003C42.6922 30.5491 44 26.4273 44 22C44 17.5727 42.6922 13.4509 40.442 9.99975C36.8982 9.96876 34.0312 7.10184 34.0003 3.55803ZM39.2736 11.9121C35.5606 11.3755 32.6245 8.43938 32.0879 4.72638C29.1275 2.9934 25.6823 2 22 2C18.3177 2 14.8725 2.9934 11.9121 4.72638C11.3755 8.43938 8.43938 11.3755 4.72638 11.9121C2.9934 14.8725 2 18.3177 2 22C2 25.6823 2.9934 29.1275 4.72638 32.0879C8.43938 32.6245 11.3755 35.5606 11.9121 39.2736C14.8725 41.0066 18.3177 42 22 42C25.6823 42 29.1275 41.0066 32.0879 39.2736C32.6245 35.5606 35.5606 32.6245 39.2736 32.0879C41.0066 29.1275 42 25.6823 42 22C42 18.3177 41.0066 14.8725 39.2736 11.9121Z"
          fill="#ECEFF1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.48237 14.9824C12.0722 14.9824 14.9824 12.0722 14.9824 8.48237C14.9824 8.32012 14.9764 8.15926 14.9647 8C18.3293 8.24682 20.9824 11.0548 20.9824 14.4824C20.9824 18.0722 18.0722 20.9824 14.4824 20.9824C11.0548 20.9824 8.24682 18.3293 8 14.9647C8.15926 14.9764 8.32012 14.9824 8.48237 14.9824Z"
          fill="#B71C1C"
          className="element-1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.9824 35.5C14.9824 31.9101 12.0722 29 8.48237 29C8.32012 29 8.15926 29.0059 8 29.0176C8.24682 25.653 11.0548 23 14.4824 23C18.0722 23 20.9824 25.9101 20.9824 29.5C20.9824 32.9276 18.3293 35.7355 14.9647 35.9824C14.9764 35.8231 14.9824 35.6622 14.9824 35.5Z"
          fill="#1A237E"
          className="element-2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.5 29C31.9102 29 29 31.9101 29 35.5C29 35.6622 29.006 35.8231 29.0176 35.9824C25.653 35.7355 23 32.9276 23 29.5C23 25.9101 25.9102 23 29.5 23C32.9276 23 35.7356 25.653 35.9824 29.0176C35.8231 29.0059 35.6623 29 35.5 29Z"
          fill="#B71C1C"
          className="element-3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29 8.48237C29 12.0722 31.9102 14.9824 35.5 14.9824C35.6623 14.9824 35.8231 14.9764 35.9824 14.9647C35.7356 18.3293 32.9276 20.9824 29.5 20.9824C25.9102 20.9824 23 18.0722 23 14.4824C23 11.0548 25.653 8.24682 29.0176 8C29.006 8.15926 29 8.32012 29 8.48237Z"
          fill="#1A237E"
          className="element-4"
        />
      </svg>
    </>
  )
}

export default Logo
