import {
  faSearch,
  faSearchLocation,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import Fuse from 'fuse.js'
import Tooltip from './Tooltip'
import { latinToCyrillicTransliteration } from '../helpers/latinToCyrillicTransliteration'

const Search = ({ locale, mapRef, places }) => {
  const [isSearchActive, setIsSearchActive] = useState(false)
  const [query, setQuery] = useState('')
  const inputRef = useRef()
  const searchRef = useRef()
  const buttonRef = useRef()
  const { formatMessage } = useIntl()
  let fuse, results, searchResults, listData

  const onSearch = (event) => {
    setQuery(event.currentTarget.value)
  }

  const flyToPlaceLocation = (event) => {
    const coordinates = [
      parseFloat(event.currentTarget.dataset.latitude),
      parseFloat(event.currentTarget.dataset.longitude),
    ]
    mapRef.current.flyTo(coordinates, 15)
    setIsSearchActive(false)
  }

  const options = {
    includeScore: true,
    keys: [`name_${locale}`],
  }

  if (places) {
    const finalQuery =
      locale === 'sr' ? latinToCyrillicTransliteration(query) : query
    fuse = new Fuse(places, options)
    results = fuse.search(finalQuery)
    searchResults = results
      .filter((result) => result.score < 0.5)
      .map((result) => result.item)

    listData = searchResults.map((place) => {
      return (
        <div
          key={place.id}
          onClick={flyToPlaceLocation}
          data-latitude={place.latitude}
          data-longitude={place.longitude}
          className="search__place"
        >
          <div className="search__place-icon">
            <img src={place.icon} alt="place icon" />
          </div>
          <div className="search__place-name">{place[`name_${locale}`]}</div>
        </div>
      )
    })
  }

  useEffect(() => {
    // Clear input field.
    !isSearchActive && setQuery('')

    // Focuse input field on open and unfocus on close.
    isSearchActive ? inputRef.current.focus() : inputRef.current.blur()
  }, [isSearchActive])

  const handleClickOutside = (event) => {
    // If click is not on the card or button.
    if (
      !searchRef.current.contains(event.target) &&
      !buttonRef.current.contains(event.target)
    ) {
      setIsSearchActive(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <>
      <Tooltip
        className="button button--round search__button"
        content={formatMessage({
          id: 'map.search.button',
        })}
        position="left"
        click={() => setIsSearchActive(!isSearchActive)}
        ref={buttonRef}
      >
        <FontAwesomeIcon icon={faSearchLocation} />
      </Tooltip>

      <div
        className={`search ${isSearchActive && 'active'} ${query && 'in-use'}`}
        ref={searchRef}
      >
        <div className="search__header">
          <div className="search__search-icon">
            <FontAwesomeIcon icon={faSearch} color="#fff" />
          </div>
          <input
            ref={inputRef}
            type="text"
            value={query}
            onChange={onSearch}
            className="search__search-field"
            placeholder={formatMessage({ id: 'map.search.title' })}
          />
          <div
            className="search__close"
            onClick={() => setIsSearchActive(false)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </div>
        <div className={`search__body ${query && 'in-use'}`}>{listData}</div>
        <div className="search__footer" />
      </div>
    </>
  )
}

export default Search
