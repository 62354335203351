import { API_TOMTOM_EN, API_TOMTOM_SR } from '../constants/api'

export const getMapType = (locale) => {
  let mapTypeData = ''

  if (locale === 'en') {
    mapTypeData = API_TOMTOM_EN + process.env.REACT_APP_TOMTOM_KEY
  } else if (locale === 'sr') {
    mapTypeData = API_TOMTOM_SR + process.env.REACT_APP_TOMTOM_KEY
  }

  return mapTypeData
}
