import { useIntl } from "react-intl";

const NotFound = () => {
  const { formatMessage } = useIntl();

  return (
    <div className="error">
      <h1>{formatMessage({ id: "error.not_found.message" })}</h1>
    </div>
  );
};

export default NotFound;
