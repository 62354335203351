import produce from "immer";
import { SET_SERVER_ERROR } from "../constants/errorConstants";

const initialState = {
  error: "",
};

const errorReducer = produce((draft, action) => {
  switch (action.type) {
    case SET_SERVER_ERROR:
      draft.error = action.payload;
      break;
    default:
      return draft;
  }
}, initialState);

export default errorReducer;
