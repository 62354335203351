import { useIntl } from 'react-intl'
import dushaSloboda from '../assets/images/dusha-sloboda-white.svg'

const About = () => {
  const { formatMessage } = useIntl()

  return (
    <div className="about">
      <div className="about__content">
        <img className="about__logo" src={dushaSloboda} alt="Dusha Sloboda" />

        <div className="about__description">
          {formatMessage({ id: 'about.description' })}
        </div>

        <div className="about__contact">
          {formatMessage({ id: 'about.contact' })}{' '}
          <a href="mailto: dusha.sloboda@gmail.com">dusha.sloboda@gmail.com</a>
        </div>
      </div>
    </div>
  )
}

export default About
