import produce from 'immer'
import { SET_CATEGORIES } from '../constants/categoriesConstants'

const initialState = {
  categories: [],
  loading: true,
}

const categoriesReducer = produce((draft, action) => {
  switch (action.type) {
    case SET_CATEGORIES:
      draft.categories = action.payload
      draft.loading = false
      break
    default:
      return draft
  }
}, initialState)

export default categoriesReducer
