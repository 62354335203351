import { put, call } from "redux-saga/effects";
import { setCategories } from "../../actions/categoriesActions";
import { setServerError } from "../../actions/errorActions";
import { getCategoriesRequest } from "../requests/categoriesRequest";

export function* getCategoriesHandler() {
  try {
    const response = yield call(getCategoriesRequest);
    yield put(setCategories(response.data));
  } catch (error) {
    yield put(setServerError(error));
  }
}
