import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { selectCurrentLanguage } from '../redux/selectors/languageSelectors'
import About from '../views/About'
import Home from '../views/Home'
import NotFound from '../views/NotFound'
import PageFooter from './PageFooter'
import PageHeader from './PageHeader'
import { selectError } from '../redux/selectors/errorSelectors'
import ServerError from '../views/ServerError'

const PageLayout = () => {
  const currentLanguage = useSelector(selectCurrentLanguage)
  const serverError = useSelector(selectError)

  return (
    <div className="layout">
      <PageHeader locale={currentLanguage} />

      <>
        <Switch>
          <Route exact path="/index.html">
            <Redirect to="/" />
          </Route>

          <Route exact path="/">
            {!serverError ? <Home /> : <ServerError />}
          </Route>

          <Route exact path="/about">
            <About />
          </Route>

          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </>

      <PageFooter locale={currentLanguage} />
    </div>
  )
}

export default PageLayout
