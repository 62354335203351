import { useEffect } from 'react'
import L from 'leaflet/dist/leaflet.js'
import 'leaflet/dist/leaflet.css'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import 'leaflet.markercluster/dist/leaflet.markercluster.js'
import ClusterGroupIcon from './ClusterGroupIcon'
import { getMapType } from '../helpers/getMapType'
import { useIntl } from 'react-intl'

const Map = ({ mapAndPlaces, locale }) => {
  const { formatMessage } = useIntl()
  const { mapRef, placesRef } = mapAndPlaces

  useEffect(() => {
    // Adding map.
    mapRef.current = L.map('map', {
      minZoom: 3,
      zoomControl: false,
    }).setView([44.811537, 20.465586], 8)

    // Adding zoom control with custom tooltips.
    const zoomControl = L.control.zoom({
      zoomInTitle: formatMessage({ id: 'map.zoomControl.in' }),
      zoomOutTitle: formatMessage({ id: 'map.zoomControl.out' }),
    })
    zoomControl.addTo(mapRef.current)

    // Adding tile server.
    // L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    //   attribution:
    //     '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    // }).addTo(mapRef.current)
    L.tileLayer(getMapType(locale), {
      attribution: '&copy; 2022 TomTom International BV',
    }).addTo(mapRef.current)

    // Adding marker cluster.
    placesRef.current = L.markerClusterGroup({
      iconCreateFunction: function (cluster) {
        return L.divIcon({
          html: ClusterGroupIcon(cluster.getChildCount()),
        })
      },
      polygonOptions: {
        weight: 1,
        color: '#008e76',
        opacity: 0.8,
        fillColor: '#00bfa5',
        fillOpacity: 0.4,
      },
    })

    // Defining path to default marker icons.
    L.Icon.Default.prototype.options.imagePath = 'images/'

    // Adding places to the map.
    mapRef.current.addLayer(placesRef.current)

    return () => mapRef.current.remove()
    // eslint-disable-next-line
  }, [mapRef, placesRef, locale])

  return <section id="map" className="map"></section>
}

export default Map
