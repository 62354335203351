import { faGoogle, faWikipediaW } from '@fortawesome/free-brands-svg-icons'
import {
  faCheckCircle,
  faInfo,
  faLocationArrow,
  faMapMarkedAlt,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import parse from 'html-react-parser'
import { useEffect, useRef, useState } from 'react'
import { isIOS } from 'react-device-detect'
import { useIntl } from 'react-intl'
import { API_PLACE_URL } from '../constants/api'
import {
  APPLE_MAPS_NAVIGATION,
  GOOGLE_MAPS_NAVIGATION,
} from '../constants/navigation'
import Logo from './Logo'
import Tooltip from './Tooltip'
import placeholderLogo from '../assets/images/placeholder-logo.png'

const PlaceCard = ({ close, closing, selectedPlace, locale }) => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const [placeData, setPlaceData] = useState()
  const [loadingData, setLoadingData] = useState(false)
  const [showZoomed, setShowZoomed] = useState(false)
  const [infoActive, setInfoActive] = useState(false)
  const [attributionsActive, setAttributionsActive] = useState(false)
  const placeCardRef = useRef()
  const zoomedRef = useRef()
  const { formatMessage } = useIntl()

  const getPlaceData = () => {
    setLoadingData(true)

    if (selectedPlace) {
      axios.get(API_PLACE_URL, { params: { id: selectedPlace, locale } }).then(
        (response) => {
          if ((response.status = 200)) {
            setPlaceData(response.data)
            setLoadingData(false)
          }
        },
        (error) => {
          console.error(error)
          setLoadingData(false)
        }
      )
    }
  }

  const handleClickOutside = (event) => {
    // If click is not on the card or zoomed image.
    if (
      !placeCardRef.current?.contains(event.target) &&
      !zoomedRef.current?.contains(event.target)
    ) {
      close()
    }
  }

  const addToClipboard = async (latitude, longitude) => {
    try {
      await navigator.clipboard.writeText(`${latitude}, ${longitude}`)
      setInfoActive(true)
      setTimeout(() => setInfoActive(false), 3000)
    } catch (err) {
      console.error('Failed to copy: ', err)
    }
  }

  useEffect(() => {
    !loadingData && document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    // eslint-disable-next-line
  }, [loadingData])

  useEffect(() => {
    getPlaceData()
    // eslint-disable-next-line
  }, [selectedPlace])

  return (
    <>
      <div className="place-card__wrapper">
        <div className={`place-card__mask ${closing && 'closing'}`} />
        {loadingData && (
          <div className="home__loader clear">
            <div className="home__loader-wrapper">
              <Logo size={120} />
            </div>
          </div>
        )}
        {!loadingData && (
          <div className={`place-card__container ${closing && 'closing'}`}>
            <div className="place-card" ref={placeCardRef}>
              {placeData ? (
                <>
                  <section className="place-card__image-wrapper">
                    {placeData.image_src && (
                      <img
                        src={placeData.image_src}
                        alt={placeData.image_alt}
                        className={`place-card__image ${
                          imageLoaded && 'active'
                        }`}
                        onLoad={() => {
                          setImageLoaded(true)
                        }}
                        onClick={() => setShowZoomed(true)}
                      />
                    )}
                    {!placeData.image_src && (
                      <div className="place-card__image-secondary">
                        <img
                          src={placeData.icon_src}
                          alt={placeData.icon_alt}
                          onLoad={() => {
                            setImageLoaded(true)
                          }}
                        />
                      </div>
                    )}
                    {!imageLoaded && (
                      <div className="place-card__image-placeholder">
                        <img src={placeholderLogo} alt="placeholder" />
                      </div>
                    )}
                  </section>

                  <section className="place-card__title">
                    {placeData.about?.name}
                  </section>

                  <section className="place-card__group">
                    <div className="place-card__badge">
                      {placeData.icon_src && (
                        <img
                          src={placeData.icon_src}
                          alt={placeData.icon_alt}
                        />
                      )}
                    </div>

                    <div className="place-card__info-animation-wrapper">
                      <div
                        className={`place-card__info ${
                          attributionsActive && 'active'
                        }`}
                        onClick={() =>
                          setAttributionsActive(!attributionsActive)
                        }
                      >
                        <FontAwesomeIcon icon={faInfo} />
                      </div>

                      {placeData.image_attributions && (
                        <div
                          className={`place-card__attributions ${
                            attributionsActive && 'active'
                          }`}
                        >
                          <h3>
                            {formatMessage({ id: 'modal.attribution.title' })}
                          </h3>
                          <a
                            href={placeData.image_attributions.image_address}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {placeData.image_attributions.image_name}
                          </a>
                          {formatMessage({ id: 'modal.attribution.by' })}
                          <a
                            href={placeData.image_attributions.author_address}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {placeData.image_attributions.author_name}
                          </a>
                          <br />
                          {formatMessage({ id: 'modal.attribution.license' })}
                          <a
                            href={placeData.image_attributions.license_address}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {placeData.image_attributions.license_name}
                          </a>
                        </div>
                      )}
                    </div>
                  </section>

                  <section className="place-card__description">
                    {placeData.about && parse(placeData.about.description)}
                  </section>

                  <section className="place-card__footer">
                    <Tooltip
                      className="place-card-footer__action"
                      content={formatMessage({ id: 'modal.tooltip.wikipedia' })}
                      position="top"
                      style={{
                        pointerEvents: placeData.about?.wikipedia_address
                          ? 'initial'
                          : 'none',
                      }}
                    >
                      <a
                        href={placeData.about?.wikipedia_address}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon
                          icon={faWikipediaW}
                          opacity={placeData.about?.wikipedia_address ? 1 : 0.4}
                        />
                      </a>
                    </Tooltip>
                    <div className="place-card-footer__separator" />
                    <Tooltip
                      className="place-card-footer__action"
                      content={formatMessage({ id: 'modal.tooltip.google' })}
                      position="top"
                    >
                      <a
                        href={`http://www.google.com/search?q=${placeData.about?.name}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={faGoogle} />
                      </a>
                    </Tooltip>
                    <div className="place-card-footer__separator" />
                    <Tooltip
                      className="place-card-footer__action"
                      content={formatMessage({
                        id: 'modal.tooltip.navigation',
                      })}
                      position="top"
                    >
                      <a
                        href={`${
                          isIOS ? APPLE_MAPS_NAVIGATION : GOOGLE_MAPS_NAVIGATION
                        }${placeData.latitude}%2C${placeData.longitude}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={faLocationArrow} />
                      </a>
                    </Tooltip>
                    <div className="place-card-footer__separator" />
                    <Tooltip
                      className="place-card-footer__action"
                      content={formatMessage({
                        id: 'modal.tooltip.coordinates',
                      })}
                      position="top"
                      click={() => {
                        addToClipboard(placeData.latitude, placeData.longitude)
                      }}
                    >
                      <FontAwesomeIcon icon={faMapMarkedAlt} />
                    </Tooltip>
                    <div className="place-card-footer__separator" />
                    <Tooltip
                      className="place-card-footer__action place-card-footer__action--close"
                      content={formatMessage({ id: 'modal.tooltip.close' })}
                      position="top"
                      click={close}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </Tooltip>
                  </section>
                </>
              ) : (
                <div>error</div>
              )}
            </div>
          </div>
        )}
      </div>

      <div
        className={`place-card__zoom ${showZoomed && 'active'}`}
        onClick={() => setShowZoomed(false)}
        ref={zoomedRef}
      >
        {placeData && placeData.image_src && (
          <img src={placeData.image_src} alt={placeData.image_alt} />
        )}
      </div>

      <div className={`place-card__copy-info ${infoActive && 'active'}`}>
        <span className="place-card-copy-info__checkmark">
          <FontAwesomeIcon icon={faCheckCircle} />
        </span>
        {formatMessage({ id: 'modal.alert.coordinates' })}
      </div>
    </>
  )
}

export default PlaceCard
