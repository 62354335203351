import L from 'leaflet/dist/leaflet.js'
import { useEffect, useRef, useState } from 'react'
import Map from '../components/Map'
import Logo from '../components/Logo'
import PlaceCard from '../components/PlaceCard'
import { filterPlacesByCategory } from '../helpers/filterPlacesByCategory'
import { useDispatch, useSelector } from 'react-redux'
import { getPlaces } from '../redux/actions/placesActions'
import { selectPlaces } from '../redux/selectors/placesSelectors'
import { selectCurrentLanguage } from '../redux/selectors/languageSelectors'
import { faStreetView } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from 'react-router'
import markerShadow from '../assets/images/marker-shadow.svg'
import locationPinImage from '../assets/images/location-pin.svg'
import Portal from '../components/Portal'
import Filter from '../components/Filter'
import Search from '../components/Search'
import Tooltip from '../components/Tooltip'
import { useIntl } from 'react-intl'
import { hasTouch } from 'detect-touch'

const Home = () => {
  const mapRef = useRef()
  const placesRef = useRef()
  const currentPositionRef = useRef()
  const currentAccuracyRef = useRef()
  const [filteredCategories, setFilteredCategories] = useState([])
  const [placesToShow, setPlacesToShow] = useState([])
  const [isGeolocationActive, setIsGeolocationActive] = useState(false)
  const [isPlaceCardActive, setIsPlaceCardActive] = useState(false)
  const [closingPlaceCard, setClosingPlaceCard] = useState(false)
  const [selectedPlace, setSelectedPlace] = useState()
  const [cameByHashLink, setCameByHashLink] = useState(false)
  const dispatch = useDispatch()
  const { places, loading } = useSelector(selectPlaces)
  const currentLanguage = useSelector(selectCurrentLanguage)
  const history = useHistory()
  const { formatMessage } = useIntl()
  let locationPin

  const gtagEvent = (category, label) => {
    window.gtag('event', 'View Place Card', {
      event_category: `Places - ${category}`,
      event_label: label,
    })
  }

  const makePlaceMarkerIcon = (markerIcon) => {
    return L.icon({
      iconUrl: markerIcon,
      iconAnchor: [24, 60],
      shadowUrl: markerShadow,
      shadowAnchor: [9, 52],
    })
  }

  const addPlaces = (placesData) => {
    const layers = placesData.map((place) => {
      const id = place.id
      const latitude = place.latitude
      const longitude = place.longitude
      const markerIcon = makePlaceMarkerIcon(place.marker)
      const name = place[`name_${currentLanguage}`]
      const category = place[`category_${currentLanguage}`]
      const nameHashtag = `#${id}_${latitude}:${longitude}_${name
        .toLowerCase()
        .split(' ')
        .join('-')}`

      const marker = L.marker(
        { lat: latitude, lon: longitude },
        { icon: markerIcon }
      )

      marker.on('click', () => {
        openPlaceCard(id, nameHashtag)
        gtagEvent(category, name)
      })

      // Add tooltip to the marker if screen is HD.
      if (!hasTouch) {
        marker.bindTooltip(`<div>${name}</div>`, {
          offset: [0, 0],
          direction: 'bottom',
          className: 'map__marker-tooltip',
        })
      }

      return marker
    })

    placesRef.current.addLayers(layers)
  }

  const onBrowserBackOrForward = (mounted) => {
    if (history.location.hash) {
      const placeId = history.location.hash.split('_')[0].slice(1)
      openPlaceCard(placeId)
    } else {
      if (mounted) {
        setClosingPlaceCard(true)

        setIsPlaceCardActive(false)

        // To reset the card when user opens the same place as previous.
        setSelectedPlace('undefined')
        setClosingPlaceCard(false)
      }
    }
  }

  const openPlaceFromHash = () => {
    if (history.location.hash) {
      setCameByHashLink(true)
      const hashData = history.location.hash.split('_')
      const placeId = hashData[0].slice(1)
      const coordinates = hashData[1].split(':')
      mapRef.current.setView(coordinates, 16)
      openPlaceCard(placeId)
    }
  }

  const openPlaceCard = (placeId, hash) => {
    setSelectedPlace(placeId)
    setIsPlaceCardActive(true)
    if (history.location.hash) return // Because Safari.
    hash && history.push(hash)
  }

  const closePlaceCard = () => {
    setClosingPlaceCard(true)

    // Wait until animation is done then close.
    setTimeout(() => {
      setIsPlaceCardActive(false)
      if (cameByHashLink) {
        history.push('/')
        setCameByHashLink(false)
      } else {
        history.goBack()
      }
      // To reset the card when user opens the same place as previous.
      setSelectedPlace('undefined')
      setClosingPlaceCard(false)
    }, 300)
  }

  const handleGeolocation = () => {
    setIsGeolocationActive(!isGeolocationActive)

    if (isGeolocationActive) {
      mapRef.current.stopLocate()
      mapRef.current.removeLayer(currentPositionRef.current)
      mapRef.current.removeLayer(currentAccuracyRef.current)
      currentPositionRef.current = null
      currentAccuracyRef.current = null
    } else {
      mapRef.current.locate({ setView: false, watch: true })
    }
  }

  const onLocationFound = (event) => {
    if (currentPositionRef.current) {
      mapRef.current.removeLayer(currentPositionRef.current)
      mapRef.current.removeLayer(currentAccuracyRef.current)
    } else {
      mapRef.current.flyTo(event.latlng, 15)
    }

    const radius = event.accuracy

    currentPositionRef.current = L.marker(event.latlng, { icon: locationPin })
      .addTo(mapRef.current)
      .bindPopup('You are within ' + radius + ' meters from this point')

    currentAccuracyRef.current = L.circle(event.latlng, {
      radius,
      stroke: false,
      fillColor: '##1a237e',
      fillOpacity: 0.2,
    }).addTo(mapRef.current)
  }

  const onLocationError = (event) => {
    alert(event.message)
  }

  const onFilterChange = (categories) => setFilteredCategories(categories)

  useEffect(() => {
    dispatch(getPlaces())
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    places &&
      setPlacesToShow(filterPlacesByCategory(places, filteredCategories))
  }, [places, filteredCategories])

  useEffect(() => {
    placesToShow && addPlaces(placesToShow)

    // To prevent adding new places on every places change.
    // eslint-disable-next-line
    return () => placesRef.current.clearLayers()
    // eslint-disable-next-line
  }, [placesToShow])

  useEffect(() => {
    // eslint-disable-next-line
    locationPin = L.icon({
      iconUrl: locationPinImage,
      iconAnchor: [21, 21],
    })

    mapRef.current.on('locationfound', onLocationFound)
    mapRef.current.on('locationerror', onLocationError)
  }, [])

  useEffect(() => {
    // For preventing an error about changing a state of an unmounted component.
    let isComponentMounted = true

    openPlaceFromHash()

    history.listen(() => {
      if (history.action === 'POP') {
        onBrowserBackOrForward(isComponentMounted)
      }
    })

    return () => {
      isComponentMounted = false
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <main className="home">
        {loading && !cameByHashLink && (
          <div className="home__loader">
            <div className="home__loader-wrapper">
              <Logo size={120} />
            </div>
          </div>
        )}

        <Map mapAndPlaces={{ mapRef, placesRef }} locale={currentLanguage} />

        <Tooltip
          className={`button button--round map__locate ${
            isGeolocationActive && 'active'
          }`}
          content={formatMessage({
            id: isGeolocationActive
              ? 'map.my.location.hide'
              : 'map.my.location.show',
          })}
          position="right"
          click={handleGeolocation}
        >
          <FontAwesomeIcon icon={faStreetView} />
        </Tooltip>

        <Filter locale={currentLanguage} onFilterChange={onFilterChange} />

        <Search
          locale={currentLanguage}
          mapRef={mapRef}
          places={placesToShow}
        />

        {isPlaceCardActive && (
          <Portal>
            <PlaceCard
              close={closePlaceCard}
              closing={closingPlaceCard}
              selectedPlace={selectedPlace}
              locale={currentLanguage}
            />
          </Portal>
        )}
      </main>
    </>
  )
}

export default Home
