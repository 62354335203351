import { IntlProvider } from 'react-intl'
import { useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import languages from './languages/languages'
import PageLayout from './layouts/PageLayout'
import { selectCurrentLanguage } from './redux/selectors/languageSelectors'

const App = () => {
  const currentLanguage = useSelector(selectCurrentLanguage)

  return (
    <IntlProvider
      locale={currentLanguage}
      messages={languages[currentLanguage]}
    >
      <BrowserRouter>
        <PageLayout />
      </BrowserRouter>
    </IntlProvider>
  )
}

export default App
