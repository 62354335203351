import { combineReducers } from "redux";
import categoriesReducer from "./categoriesReducer";
import errorReducer from "./errorReducer";
import languageReducer from "./languageReducer";
import placesReducer from "./placesReducer";

const rootReducer = combineReducers({
  places: placesReducer,
  categories: categoriesReducer,
  language: languageReducer,
  error: errorReducer
});

export default rootReducer;
