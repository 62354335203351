import { GET_PLACES, SET_PLACES } from '../constants/placesConstants'

export const getPlaces = () => ({
  type: GET_PLACES,
})

export const setPlaces = (places) => ({
  type: SET_PLACES,
  payload: places,
})
