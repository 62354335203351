import React, { forwardRef } from 'react'
import { hasTouch } from 'detect-touch'

const Tooltip = forwardRef(
  ({ children, className, style, content, position, click }, ref) => {
    return (
      <div
        className={`tooltip__wrapper ${className}`}
        style={style}
        onClick={click}
        ref={ref}
      >
        {children}
        <div className={`tooltip-${position} ${!hasTouch && 'active'}`}>
          {content}
        </div>
      </div>
    )
  }
)

export default Tooltip
