import { letterMapBase, letterMapDerived } from '../constants/letterMaps'

export const latinToCyrillicTransliteration = (input) => {
  let transliterated = input.toLowerCase()

  for (const letter in letterMapDerived) {
    transliterated = transliterated.replaceAll(letter, letterMapDerived[letter])
  }

  for (const letter in letterMapBase) {
    transliterated = transliterated.replaceAll(letter, letterMapBase[letter])
  }

  return transliterated
}
