import produce from 'immer'
import { SET_PLACES } from '../constants/placesConstants'

const initialState = {
  places: [],
  loading: true,
}

const placesReducer = produce((draft, action) => {
  switch (action.type) {
    case SET_PLACES:
      draft.places = action.payload
      draft.loading = false
      break
    default:
      return draft
  }
}, initialState)

export default placesReducer
