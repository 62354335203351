import { put, call } from "redux-saga/effects";
import { setServerError } from "../../actions/errorActions";
import { setPlaces } from "../../actions/placesActions";
import { getPlacesRequest } from "../requests/placesRequest";

export function* getPlacesHandler() {
  try {
    const response = yield call(getPlacesRequest);
    yield put(setPlaces(response.data));
  } catch (error) {
    console.error(error);
    yield put(setServerError(error));
  }
}
