import { takeLatest } from 'redux-saga/effects'
import { GET_CATEGORIES } from '../constants/categoriesConstants'
import { GET_PLACES } from '../constants/placesConstants'
import { getCategoriesHandler } from './handlers/categoriesHandler'
import { getPlacesHandler } from './handlers/placesHandler'

export function* watcherSaga() {
  yield takeLatest(GET_PLACES, getPlacesHandler)
  yield takeLatest(GET_CATEGORIES, getCategoriesHandler)
}
