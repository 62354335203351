import { GET_CATEGORIES, SET_CATEGORIES } from "../constants/categoriesConstants";

export const getCategories = () => ({
  type: GET_CATEGORIES,
});

export const setCategories = (categories) => ({
  type: SET_CATEGORIES,
  payload: categories,
});
