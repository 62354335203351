const ClusterGroupIcon = (count = 0) => {
  let size = ''

  if (count <= 10) size = 'sm'
  else if (count > 10 && count <= 20) size = 'md'
  else if (count > 20 && count <= 50) size = 'lg'
  else if (count > 40 && count <= 100) size = 'xl'
  else size = 'xxl'

  return `
    <div class="map__cluster-group-outer map__cluster-group-outer--${size}">
      <div class="map__cluster-group-inner map__cluster-group-inner--${size}">
        ${count}
      </div>
    </div>
  `
}

export default ClusterGroupIcon
