import { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import Logo from '../components/Logo'
import { Link } from 'react-router-dom'
import enFlag from '../assets/images/flags/en.png'
import srFlag from '../assets/images/flags/sr.png'

const PageHeader = ({ locale }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const menuRef = useRef()
  const { formatMessage } = useIntl()

  const handleClickOutside = (event) => {
    // If click is not on the menu button.
    if (!menuRef.current.contains(event.target)) {
      setIsMenuOpen(false)
    }
  }

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isMenuOpen])

  return (
    <>
      <Link to="/">
        <div className="layout__header-logo">
          <Logo size={120} />
        </div>
      </Link>
      <div className="layout__header-logo layout__header-logo--ghost" />

      <header className="layout__header">
        <div className="layout__header-group">
          <div className="layout__header-title-group">
            <Link to="/" className="layout__header-title">
              {formatMessage({ id: 'header.title' })}
            </Link>

            <div className="layout__header-tagline">
              {formatMessage({ id: 'header.tagline' })}
            </div>
          </div>

          <div className="layout__header-language" ref={menuRef}>
            <div
              className="header-language__button"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <img src={locale === 'en' ? enFlag : srFlag} alt="language" />
            </div>
            <div className={`header-language__menu ${isMenuOpen && 'active'}`}>
              <a href="https://touristmapofserbia.com">
                <div
                  className="header-language__menu-item"
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                  <img src={enFlag} alt="en" />
                </div>
              </a>
              <a href="https://rs.touristmapofserbia.com">
                <div
                  className="header-language__menu-item"
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                  <img src={srFlag} alt="sr" />
                </div>
              </a>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default PageHeader
