import produce from 'immer'
import { SET_LANGUAGE } from '../constants/languageConstants'

const initialState = {
  currentLanguage: process.env.REACT_APP_LOCALE,
}

const languageReducer = produce((draft, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      draft.currentLanguage = action.payload
      break
    default:
      return draft
  }
}, initialState)

export default languageReducer
