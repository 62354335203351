import { faFilter, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { getCategories } from '../redux/actions/categoriesActions'
import { selectCategories } from '../redux/selectors/categoriesSelector'
import Tooltip from './Tooltip'

const Filter = ({ locale, onFilterChange }) => {
  const [isFilterActive, setIsFilterActive] = useState(false)
  const filterRef = useRef()
  const { formatMessage } = useIntl()
  const checkboxesRef = useRef([])
  const dispatch = useDispatch()
  const { categories } = useSelector(selectCategories)

  const addToCheckboxesRef = (element) => {
    if (element && !checkboxesRef.current.includes(element)) {
      checkboxesRef.current.push(element)
    }
  }

  const onCheckboxChange = () => {
    const checkedCategories = []

    checkboxesRef.current.forEach((item) => {
      if (item.checked) {
        checkedCategories.push(item.value)
      }
    })

    onFilterChange(checkedCategories)
  }

  const onClearCheckboxes = () => {
    checkboxesRef.current.forEach((item) => {
      item.checked = false
    })

    onFilterChange([])
  }

  const handleClickOutside = (event) => {
    // If click is not on the card.
    if (!filterRef.current.contains(event.target)) {
      setIsFilterActive(false)
    }
  }

  useEffect(() => {
    dispatch(getCategories())

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Tooltip
        className="button button--round filter__button"
        content={formatMessage({
          id: 'map.filter.button',
        })}
        position="left"
        click={() => setIsFilterActive(true)}
      >
        <FontAwesomeIcon icon={faFilter} />
      </Tooltip>

      <div className={`filter ${isFilterActive && 'active'}`} ref={filterRef}>
        <div className="filter__header">
          {formatMessage({ id: 'map.filter.title' })}
          <div
            className="filter__close"
            onClick={() => setIsFilterActive(false)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </div>

        <div className="filter__body">
          {categories &&
            categories.map((category) => (
              <div key={category.id}>
                <label className="filter__checkbox-wrapper">
                  <input
                    type="checkbox"
                    value={category.name}
                    className="filter__checkbox"
                    ref={addToCheckboxesRef}
                    onChange={onCheckboxChange}
                  />
                  <div className="filter__checkbox-icon">
                    <img src={category.icon_url} alt="category" />
                    <div></div>
                  </div>
                  <span className="filter__checkbox-label">
                    {category[`title_${locale}`]}
                  </span>
                </label>
              </div>
            ))}
        </div>

        <div className="filter__footer">
          <div className="button button--capsule" onClick={onClearCheckboxes}>
            {formatMessage({ id: 'map.filter.clear' })}
          </div>
        </div>
      </div>
    </>
  )
}

export default Filter
