import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import Tooltip from '../components/Tooltip'

const PageFooter = ({ locale }) => {
  const { formatMessage } = useIntl()

  return (
    <footer className="layout__footer">
      <span className="layout__footer-text">
        {formatMessage({ id: 'footer.created' })}
        <Tooltip
          content={formatMessage({ id: 'about.tooltip' })}
          position="top"
          style={{ display: 'inline-block' }}
        >
          <Link to={`/about`}>{formatMessage({ id: 'footer.by' })}</Link>
        </Tooltip>
      </span>
    </footer>
  )
}

export default PageFooter
