import { useIntl } from "react-intl";

const ServerError = () => {
  const { formatMessage } = useIntl();

  return (
    <div className="error">
      <h1>{formatMessage({ id: "error.server.message" })}</h1>
    </div>
  );
};

export default ServerError;
